.forum-post-list-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    padding: 12px 16px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    color: var(--text-color);

    .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        gap: 4px;
    }

    .meta {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        min-width: 120px;
    }

    @media (max-width: 600px) {
        flex-direction: column;

        .meta {
            align-items: flex-start;
        }
    }
}
