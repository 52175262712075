#forum-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    #forum-controls {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 8px;

        #forum-filter-types {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    #forum-posts {
        max-width: 650px;
        width: 66%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow-wrap: anywhere;
    }
}