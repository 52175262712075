#forgot-password-page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .logo {
        height: 60px;
    }

    #forgot-password-section,
    #reset-password-section,
    #reset-success-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto;

        &.align-start {
            align-items: flex-start;
        }

        &.align-center {
            align-items: center;
        }

        #reset-password-section-fields {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.align-start {
                align-items: flex-start;
            }
        }

        label {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        input {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
            box-sizing: border-box;
        }
    }

    #forgot-error-message,
    #reset-error-message {
        display: none;
        color: var(--warn-color);
        margin-bottom: 10px;
        font-size: 14px;
    }

    #reset-success-section {
        p {
            text-align: center;
            margin: 10px 0;
        }

        a button {
            margin-top: 20px;
        }
    }
}