.adsbygoogle {
    min-height: 100px;
    min-width: 200px;
    display: block;
    text-align: center;

    &.with-border {
        border: 1px solid red;
    }
}

.vertical-banner-ad {
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 160px;
    max-width: 250px;
    height: 728px;
    display: block;
}

.vertical-banner-ad-left {
    margin-right: 16px;
}

.vertical-banner-ad-right {
    margin-left: 16px;
}