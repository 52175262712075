#comment-list-wrapper {
    margin: auto;
    width: 100%;
    max-width: 800px;

    #comment-list-create-tab-comment {
        padding: 15px;
    }
}

#create-comment-button-template,
#create-comment-editor-template {
    display: none;
}

.create-comment-editor-view,
.create-comment-preview-view {
    display: flex;
    flex-direction: column;
}

.create-comment-errors {
    color: var(--warn-color);
}

.create-comment-preview {
    border: 1px solid var(--border-color);
    border-radius: 8px;

    &-view {
        display: flex;
        flex-direction: column;

        &.hidden {
            display: none;
        }

        .create-comment-preview {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 150px;
            min-width: 300px;
        }
    }
}

.create-comment-editor-view.hidden,
.create-comment-errors.hidden {
    display: none;
}
