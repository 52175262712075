.tab-rating-pips {
  display: flex;
  gap: 2px;

  .pip-container {
    position: relative;
    width: 15px;
    height: 15px;
  }

  .pip {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1.5px solid var(--border-color);
  }

  .background {
    background-color: var(--background-color);
  }

  .filled {
    background-color: var(--accent-color);
    clip-path: inset(0 calc(100% - var(--fill-percentage, 100%)) 0 0);
  }

  @media (max-width: 600px) {
    .pip-container {
      width: 13px;
      height: 13px;
    }

    .pip {
      border: 1px solid var(--border-color);
    }
  }

  @media (max-width: 450px) {
    .pip-container {
      width: 12px;
      height: 12px;
    }
  }
}