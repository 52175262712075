#home-page-highlight-reel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;

    .wrapper {
        // max width set by .content-max-width class
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 32px;
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;

        .reel-item {
            background-color: white;
            border-radius: 5px;
            overflow: hidden;
            width: 300px;
            height: 200px;
            box-shadow: 0px 2px 5px var(--focus-shadow-color);

            .image-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 140px;

                img {
                    width: 100%;
                    // height: 100%;
                }
            }

            .text-box {
                text-align: center;
                padding: 16px 8px;
                background-color: var(--primary-color);
                color: white;
                font-size: 20pt;
                font-weight: bold;
                height: 60px;
            }
        }
    }
}
