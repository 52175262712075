#submission-unsaved-changes-exist-flag {
    display: none;
    position: fixed;
    right: 12px;
    top: 64px;
    background: var(--warn-color);
    padding: 8px;
    z-index: 1000;
}

#save-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
}

#tab-text-field {
    width: initial;
    height: initial;
    max-width: 80%;
    min-height: 200px;
    min-width: 100px;
    resize: both;

    @media (max-width: 900px) {
        min-width: calc(100% - 24px);
        max-width: calc(100% - 24px);
        width: calc(100% - 24px);
        resize: vertical;
    }
}

#submission-page-errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 28px);
    padding: 0 28px;
    margin-top: 0;
    margin-bottom: 0;
}
