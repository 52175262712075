#appbar-dropdown-toggle-button-container {
    display: inline-block;
    position: relative;

    #appbar-dropdown-toggle-button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px;
        font-size: 18px;
        color: var(--text-color);
    }
}

#appbar-dropdown-menu {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: var(--popup-menu-background-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 0;
    z-index: 1000;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    pointer-events: none;
    top: 64px;
    right: 20px;

    &.show {
        display: block;
        pointer-events: auto;
    }

    .appbar-dropdown-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .appbar-dropdown-button {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        color: var(--text-color);

        &:hover {
            background-color: var(--active-background-color);
        }
    }
}

@media (max-width: 600px) {
    #appbar-dropdown-menu {
        right: 8px;
    }
}
