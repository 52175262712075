#tab-text-view-container {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;

    #tab-text-view-controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 24px;
        row-gap: 8px;
        flex-wrap: wrap;

        .tab-text-view-control-group {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

    .tab-and-ads-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;

        #tab-text-view-text {
            flex: 1;
            margin: 0;
            overflow: auto;
            box-sizing: border-box;
            overflow-y: hidden;

            &.bold {
                font-weight: bold;
            }
        }
    }
}
