#cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #222;
    color: white;
    padding: 10px;
    text-align: center;
    z-index: 9999;
}