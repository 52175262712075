#tabs-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    #search-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    #tabs-advanced-filters {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;

        auto-complete-search {
            margin-top: 10px;
        }
    }
}
