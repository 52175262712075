/* See markdown.scss in the global style sheets for the .markdown styles */

.comment-reply {
    max-width: 770px;
    padding-left: 40px;
    margin-top: 0;

    @media (max-width: 600px) {
        padding-left: 30px;
    }
}

.comment-replying-to {
    margin: 0;
    border-radius: 8px;
    background-color: var(--comment-code-inline-background-color);
    padding: 10px;
}

.child-comments {
    max-height: 100%;
    overflow: hidden;

    &.collapsed {
        max-height: 0;
        overflow: hidden;
    }
}