.markdown {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin-top: 30px;
}

.markdown blockquote {
    background-color: var(--comment-code-block-background-color);
    padding: 10px;
    border-radius: 4px;
}

.markdown pre {
    background-color: var(--comment-code-block-background-color);
    color: var(--comment-code-block-foreground-color);
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
    font-family: monospace;
    margin: 8px 0;
}

.markdown code {
    font-family: monospace;
    background-color: var(--comment-code-inline-background-color);
    color: var(--comment-code-inline-foreground-color);
    padding: 2px 4px;
    border-radius: 3px;
}

.markdown pre code {
    background-color: inherit;
    color: inherit;
    padding: 0;
    border-radius: 0;
}