#chat-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* Prevent page-level scroll */
    overflow: hidden;


    #chat-page-title {
        text-align: center;
        margin-bottom: 0;
    }

    #submission-comments-reload-button {
        color: var(--primary-color);
        cursor: pointer;
    }

    #submission-comments-container {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        box-sizing: border-box;

        .submission-comment {
            display: flex;
            flex-direction: column;
            max-width: 80%;
            padding: 8px 12px 0 8px;
            border-radius: 8px;
            word-wrap: break-word;
            background-color: var(--reply-comment-background-color);
            align-items: flex-start;

            &.user {
                align-self: flex-end;
            }

            &.other {
                align-self: flex-start;
            }

            &-by,
            &-text-container {
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .submission-comment-input-container {
        padding: 10px;
        border-top: 1px solid var(--border-color);
        box-sizing: border-box;

        #submission-comment-input {
            width: 100%;
            height: 4em;
            resize: none;
            box-sizing: border-box;

            &.expanded {
                height: 12em;
            }
        }

        #submission-comment-input-controls-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            #create-submission-comment-button {
                width: 100%;
            }

            #submission-comment-input-size-toggle {
                margin-left: 8px;
            }
        }
    }
}