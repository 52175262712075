#example-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;


    #example-page-title {
        color: orange;
    }

    #example-test-box {
        width: 500px;
        height: 200px;
        background-color: lightsalmon;
    }

    .example-page-buttons-section {
        margin-top: 20px;
    }
}