.tab-submission-attributes {
    padding: 12px;
    width: calc(100% - 24px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 32px;
}

.tab-submission-attribute {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tab-submission-attribute-label {
    font-weight: bold;
    margin: 0;
}

.tab-submission-attribute-value {
    font-weight: normal;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tab-submission-text-wrapper {
    display: block;
    padding: 12px;
    width: calc(100% - 24px);
    margin: auto;
    overflow-x: auto;
    white-space: nowrap;
}

.tab-submission-text-wrapper pre {
    width: auto;
    max-width: 100%;
    overflow-x: auto;
}