.icon-button,
.raised-button,
.outlined-button,
.text-button {
    color: inherit;
    background-color: transparent;
    border: none;
    box-shadow: none;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s ease-in-out;
}

.floating-action-button {
    position: fixed;
    bottom: 40px;
    right: 50px;
    z-index: 1000;
}

/* Hover styles only if not disabled */
.icon-button:not(:disabled):hover,
.outlined-button:not(:disabled):hover,
.text-button:not(:disabled):hover,
.raised-button:not(:disabled):hover {
    box-shadow: 0px 2px 5px var(--focus-shadow-color);
}

/* Disabled styles */
.icon-button:disabled,
.raised-button:disabled,
.outlined-button:disabled,
.text-button:disabled {
    color: var(--disabled-color);
    background-color: transparent;
    border-color: var(--disabled-color);
    box-shadow: none; /* No shadow when disabled */
    cursor: not-allowed;
    opacity: 0.6;
}

/* Button style definitions */
.icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    color: var(--text-color);
}

.icon-button.small {
    width: 30px;
    height: 30px;
}

.raised-button {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 5px var(--button-shadow-color);
}

.outlined-button {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid var(--border-color);
}

.text-button {
    background: none;
    border: none;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

/* Primary, Accent, Warn styles */
.raised-button.primary {
    background-color: var(--primary-color);
    color: #fff;
}
.raised-button.accent {
    background-color: var(--accent-color);
    color: #fff;
}
.raised-button.warn {
    background-color: var(--warn-color);
    color: #fff;
}

.outlined-button.primary {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}
.outlined-button.accent {
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
}
.outlined-button.warn {
    border: 2px solid var(--warn-color);
    color: var(--warn-color);
}

.text-button.primary {
    color: var(--primary-color);
}
.text-button.accent {
    color: var(--accent-color);
}
.text-button.warn {
    color: var(--warn-color);
}

.icon-button.primary,
.icon-button.accent,
.icon-button.warn {
    color: white;
    box-shadow: 0px 2px 5px var(--button-shadow-color);
}

.icon-button.primary {
    background-color: var(--primary-color);
}
.icon-button.accent {
    background-color: var(--accent-color);
}
.icon-button.warn {
    background-color: var(--warn-color);
}

/* Hover styles for specific types only if not disabled */
.icon-button.primary:not(:disabled):hover,
.outlined-button.primary:not(:disabled):hover,
.text-button.primary:not(:disabled):hover,
.raised-button.primary:not(:disabled):hover {
    box-shadow: 0px 2px 5px var(--focus-shadow-color);
}
.icon-button.accent:not(:disabled):hover,
.outlined-button.accent:not(:disabled):hover,
.text-button.accent:not(:disabled):hover,
.raised-button.accent:not(:disabled):hover {
    box-shadow: 0px 2px 5px var(--focus-shadow-color);
}
.icon-button.warn:not(:disabled):hover,
.outlined-button.warn:not(:disabled):hover,
.text-button.warn:not(:disabled):hover,
.raised-button.warn:not(:disabled):hover {
    box-shadow: 0px 2px 5px var(--focus-shadow-color);
}

@media (max-width: 600px) {
    .floating-action-button {
        bottom: 20px;
        right: 25px;
    }
}
