#login-dialog {

    #login-username-field-wrapper,
    #login-password-field-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 8px;

        .login-error-message {
            color: var(--warn-color);
            font-size: 0.875em;
            margin-top: 4px;
            display: block;

            &[aria-hidden="true"] {
                display: none;
            }
        }

        #toggle-password-visibility {
            margin-left: 8px;
            background: none;
            border: none;
            cursor: pointer;
            color: var(--accent-color);
        }
    }

    #login-form-error-message {
        color: var(--warn-color);
        font-size: 0.875em;
        margin-top: 4px;
        display: block;

        &[aria-hidden="true"] {
            display: none;
        }
    }

    #login-button-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        .login-loading-spinner-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}