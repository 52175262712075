#home-page-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--home-page-hero-background-color);
    width: 100%;
    color: white;

    .wrapper {
        // max width set by .content-max-width class
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;

        .title {
            margin-top: 32px;
            margin-bottom: 0;
            font-size: 3em;
        }

        .subtitle {
            margin-top: 16px;
            margin-bottom: 0;
        }

        .tagline {
            margin: 0;
        }

        .search-bar {
            margin-top: 64px;
            margin-bottom: 48px;

            input[type="text"] {
                color: var(--home-page-hero-search-field-text-color);
            }

            form {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                #search-button {
                    margin-left: 4px;
                }
            }

            @media (max-width: 450px) {
                width: 100%;

                form {
                    justify-content: center;
                }
            }
        }
    }
}
