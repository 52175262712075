a.grid-row {
  border-bottom: var(--thin-border);
}

.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7 {
  display: grid;
}

.grid-2 .table-header,
.grid-3 .table-header,
.grid-4 .table-header,
.grid-5 .table-header,
.grid-6 .table-header,
.grid-7 .table-header {
  font-weight: bold;
}

.grid-2 a,
.grid-3 a,
.grid-4 a,
.grid-5 a,
.grid-6 a,
.grid-7 a {
  color: var(--text-color);
  font-weight: normal;
}

.grid-2 div,
.grid-3 div,
.grid-4 div,
.grid-5 div,
.grid-6 div,
.grid-7 div {
  text-align: left;
  padding: 12px 24px;
}

.grid-2 {
  grid-template-columns: repeat(2, minmax(0, auto));
}

.grid-3 {
  grid-template-columns: repeat(3, minmax(0, auto));
}

.grid-4 {
  grid-template-columns: repeat(4, minmax(0, auto));
}

.grid-5 {
  grid-template-columns: repeat(5, minmax(0, auto));
}

.grid-6 {
  grid-template-columns: repeat(6, minmax(0, auto));
}

.grid-7 {
  grid-template-columns: repeat(7, minmax(0, auto));
}

.grid-row {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
}

.grid-row:hover {
  background-color: var(--active-background-color);
}

@media (max-width: 450px) {

  .grid-2 div,
  .grid-3 div,
  .grid-4 div,
  .grid-5 div,
  .grid-6 div,
  .grid-7 div {
    padding: 8px;
  }
}