#home-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;


    #home-page-content-highlights-section {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 0 16px;
        width: calc(100% - 32px);

        .home-page-highlight {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 400px;
            max-width: 1368px;
            border: 1px solid var(--border-color);
            border-radius: 8px;

            &-title {
                width: calc(100% - 32px);
                padding: 0 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 10px;
                margin-bottom: 0;
            }

            &-bottom-more {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        #home-page-recent-comments-highlight {
            .home-page-highlight-content {
                padding: 0 24px;
            }
        }
    }
}