#tab-page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    #tab-page-top-section,
    #tab-page-comments {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    #tab-page-game-name,
    #tab-page-platforms-list {
        margin-top: 4px;

        &:first-child {
            margin-top: 0;
        }
    }

    #tab-page-rating-buttons {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin-top: 8px;

        .icon-button {
            border: 2px solid var(--border-color);

            &.filled {
                background-color: var(--accent-color);
            }
        }
    }

    #tab-page-rating-section,
    #tab-page-favorite-section {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        padding: 10px;
        overflow: hidden;
    }

    #favorite-tab-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #tab-page-my-rating,
    .tab-page-rating-status {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .tab-page-hidden {
        display: none;
    }

    #tab-page-autoscroll-start-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

#tab-page-floating-scroller-controls {
    display: none;
    position: fixed;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 50%;
    gap: 5px;
    transform: translateX(-50%);
    z-index: 600;
    background-color: var(--active-background-color);
    padding: 4px 12px;
    border-radius: 5px;
    border: 1px solid var(--border-color);

    .controls-element {
        margin: 0;
        text-align: center;
    }
}

#tab-page-ad-sticky-bottom {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    background-color: transparent;
    text-align: center;

    #div-gpt-ad-1741135231571-0 {
        display: block;
        min-height: 50px;
        max-height: 90px;
    }
}