/* WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #333333;
}

/* Firefox (Limited support) */
* {
    /* Thin scrollbar */
    scrollbar-width: thin;
    /* Thumb and track colors */
    scrollbar-color: #666666 #aaaaaa;
}