:root {
    --primary-color: #7c9952;
    --accent-color: #66ada4;
    --warn-color: #e60000;
    --text-color: #272727;
    --background-color: #ffffff;
    --side-nav-background-color: #fbfbfb;
    --border-color: #e0e0e0;
    --active-text-color: #858585;
    --active-background-color: #eeeeee;
    --reply-comment-background-color: #eaeaea;
    --appbar-background-color: #f5f5f5;
    --popup-menu-background-color: #ffffff;
    --disabled-color: #929292;
    --light-shadow-color: rgba(0, 0, 0, 0.2);
    --button-shadow-color: rgba(0, 0, 0, 0.3);
    --focus-shadow-color: rgba(0, 0, 0, 0.7);
    --loading-overlay-color: rgba(255, 255, 255, 0.5);

    --home-page-hero-background-color: #7c9952;
    --home-page-hero-search-field-text-color: #272727;

    --comment-code-inline-background-color: #f4f4f6;
    --comment-code-inline-foreground-color: #d63384;
    --comment-code-block-background-color: rgba(249, 249, 251, 0.85);
    --comment-code-block-foreground-color: rgba(44, 62, 80, 0.85);

    --tab-submission-status-draft-color: #ffa726;
    --tab-submission-status-submitted-color: #42a5f5;
    --tab-submission-status-approved-color: #66bb6a;
    --tab-submission-status-denied-color: #ef5350;
    --tab-submission-status-unknown-color: #272727;
}

[data-theme="dark"] {
    --primary-color: #677f45;
    --accent-color: #348d81;
    --warn-color: #c00000;
    --text-color: #cacaca;
    --background-color: #252525;
    --side-nav-background-color: #393939;
    --border-color: #595959;
    --active-text-color: #727272;
    --active-background-color: #5F5F5F;
    --reply-comment-background-color: #484848;
    --appbar-background-color: #333333;
    --popup-menu-background-color: #393939;
    --disabled-color: #929292;
    --light-shadow-color: rgba(0, 0, 0, 0.4);
    --button-shadow-color: rgba(0, 0, 0, 0.5);
    --focus-shadow-color: rgba(0, 0, 0, 0.9);
    --loading-overlay-color: rgba(0, 0, 0, 0.25);

    --home-page-hero-background-color: #444444;
    --home-page-hero-search-field-text-color: #272727;

    --comment-code-inline-background-color: #393e46;
    --comment-code-inline-foreground-color: #ff79c6;
    --comment-code-block-background-color: rgba(46, 46, 62, 0.85);
    --comment-code-block-foreground-color: rgba(248, 248, 242, 0.85);

    --tab-submission-status-draft-color: #ffcc80;
    --tab-submission-status-submitted-color: #90caf9;
    --tab-submission-status-approved-color: #81c784;
    --tab-submission-status-denied-color: #e57373;
    --tab-submission-status-unknown-color: #bdbdbd;
}