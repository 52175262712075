.tabs-list-item {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    box-shadow: 2px 2px 5px var(--light-shadow-color);
    width: calc(100% - 30px);
    margin: auto;
    padding: 10px;
    color: var(--text-color);

    .tabs-list-item-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
    }

    .tabs-list-item-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}
