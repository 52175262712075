@media print {

    body,
    html {
        height: auto;
        overflow: visible;
    }

    .print-force-hide {
        display: none !important;
    }

    .tab-print-break {
        break-inside: avoid;
        display: block;
    }

    #tab-page-wrapper {
        overflow: visible;
        height: auto;
    }

    .tab-and-ads-wrapper {
        padding: 0 !important;
    }

    #appbar,
    #side-nav,
    #tab-page-my-rating,
    #tab-page-global-ratings,
    #tab-page-rating-stars,
    #tab-page-favorite-section,
    #tab-page-views,
    #tab-page-media-link,
    #tab-text-view-controls,
    #comment-list-wrapper {
        display: none !important;
    }

    #tab-page-top-section {
        padding: 0 !important;
        align-items: flex-start !important;
    }

    #tab-page-top-section h1,
    #tab-page-top-section h2,
    #tab-page-top-section h3,
    #tab-page-top-section h4,
    #tab-page-top-section h5,
    #tab-page-top-section h6,
    #tab-page-top-section p {
        font-size: 1em !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    ins,
    ad-display,
    ad-multiplex,
    ad-in-feed-text-only,
    ad-in-article {
        display: none !important;
    }
}