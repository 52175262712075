#top-tabs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    #top-tabs-filters {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}