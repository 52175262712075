.appbar {
    display: flex;
    flex-direction: row;
    background-color: var(--appbar-background-color);
    color: var(--text-color);
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;

    .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        text-decoration: none;
    }

    .logo-and-title {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: margin-left 0.2s ease-in-out;
        color: var(--text-color);
    }

    .logo {
        height: 100%;
        margin-right: 10px;
        transition: height 0.2s ease-in-out;
    }

    .title {
        font-size: 1.55em;
        font-weight: 500;
        transition: font-size 0.2s ease-in-out;
    }

    .right {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }
}

#appbar-main {
    height: 64px;
    z-index: 500;

    .nav-item {
        display: inline-flex;
        color: var(--text-color);

        &.active {
            text-decoration: underline;
        }
    }
}

#appbar-sub {
    height: 32px;
    z-index: 499;
    justify-content: flex-end;
    background-color: var(--accent-color);
    color: white;

    .nav-item {
        display: inline-flex;
        color: white;
    }
}

#side-nav-toggle-button {
    display: none;
}

@media (max-width: 690px) {
    .appbar {
        padding: 0 8px;
    }

    #appbar-main {
        .nav-item {
            display: none;
        }
    }

    #side-nav-toggle-button {
        display: inline-flex;
    }
}

@media (max-width: 450px) {
    .appbar-logo-and-title {
        margin-left: 4px;
        flex-direction: column;
    }

    #appbar {
        .logo {
            height: 60%;
        }

        .title {
            font-size: 1em;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
