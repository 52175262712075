input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    box-sizing: border-box;
    background-color: var(--background-color);
    font-size: 14px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    /* Transition only theme colors */
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    caret-color: var(--text-color);
    color: var(--text-color);
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 2px 5px var(--focus-shadow-color);
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder {
    color: var(--disabled-color);
    font-style: italic;
}

select {
    max-width: 300px;
    padding: 8px;
    box-sizing: border-box;
    background-color: var(--background-color);
    font-size: 14px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    color: var(--text-color);
    appearance: none;
}

select:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 2px 5px var(--focus-shadow-color);
}

select:disabled {
    background-color: var(--disabled-background-color);
    color: var(--disabled-color);
    border-color: var(--border-color);
    cursor: not-allowed;
}

textarea {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 2px solid var(--border-color);
    border-radius: 8px;
    padding: 10px;
    width: 300px;
    height: 150px;
    outline: none;
    font-size: 16px;

    &.card-style:focus {
        border-color: var(--primary-color);
        box-shadow: 0 0 0 2px var(--primary-color);
    }
}