#dialog-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    justify-content: center;
    align-items: center;

    .dialog {
        display: none; // Will be flex on open
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 270px; // Can be overridden on container directly
        padding: 6px 12px 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        background-color: var(--popup-menu-background-color);
        position: relative;

        .dialog-title-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;


            h2 {
                padding: 0;
                margin: 0;
            }
        }
    }
}