#home-page-chips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;

    .wrapper {
        // max width controlled by .content-max-width class
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        width: calc(100% - 32px);
        overflow-x: auto;
        padding: 16px;

        .chip {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            overflow: hidden;
            width: 180px;
            height: 48px;
            text-align: center;
            box-shadow: 0px 2px 5px var(--focus-shadow-color);
            color: white;
            background-color: var(--primary-color);
        }
    }
}
