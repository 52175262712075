#side-nav {
    width: 145px;
    height: 100%;
    background-color: var(--side-nav-background-color);
    color: var(--text-color);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    border-right: 1px solid var(--border-color);
    flex-shrink: 0;
    flex-grow: 0;
    transition: all 0.2s ease-in-out;
    display: none;
    z-index: 1000;

    #side-nav-toggle-button-inside-side-nav {
        margin-top: 12px;
        margin-left: 8px;
    }

    a {
        color: var(--text-color);
        text-decoration: none;
        white-space: nowrap;

        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 6px 10px;
            white-space: nowrap;
            width: 145px;
            transition: all 0.2s ease-in-out;

            .side-nav-label {
                font-weight: normal;
                font-size: 1em;
                margin-left: 4px;

                &.active {
                    text-decoration: underline;
                }
            }
        }
    }

    #side-nav-random {
        cursor: pointer;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    @media (max-width: 450px) {
        width: 115px;

        a {
            li {
                padding: 6px 6px;

                .side-nav-label {
                    font-size: 0.7rem;
                    margin-left: 3px;
                }
            }
        }
    }
}
