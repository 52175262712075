#create-account-page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .logo {
        height: 60px;
    }
}

#create-account-section,
#verification-section,
#verification-form,
#success-message-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#create-account-section,
#verification-section,
#verification-form {
    align-items: flex-start;
}

#success-message-section {
    align-items: center;
}