/* Base styles */

$content-max-width: 1368px;

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  // height: 100%;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 1rem !important;
  background-color: var(--background-color);
  color: var(--text-color);
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 500;
}

hr {
  border: none;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  max-width: $content-max-width;
  background-color: var(--border-color);
}

svg {
  color: var(--text-color);
}

svg path {
  fill: var(--text-color);
}

.font-size-small {
  font-size: 0.7rem !important;
}

.bold {
  font-weight: bold;
}

.italics {
  font-style: italic;
}

.warn-color {
  color: var(--warn-color);
}

.rotate {
  transition: transform 0.2s ease-in-out;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--loading-overlay-color);
  display: none;
  /* Block all clicks */
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  &.active {
    display: block;
    /* Block interactions */
    pointer-events: all;
  }
}

.content-max-width {
  max-width: $content-max-width;
}

.vertical-section-spacer-extra-extra-small {
  margin: 0;
  padding: 0;
  height: 10px;
}

.vertical-section-spacer-extra-small {
  margin: 0;
  padding: 0;
  height: 20px;
}

.vertical-section-spacer-small {
  margin: 0;
  padding: 0;
  height: 50px;
}

.vertical-section-spacer-medium {
  margin: 0;
  padding: 0;
  height: 100px;
}

.vertical-section-spacer-large {
  margin: 0;
  padding: 0;
  height: 200px;
}

@media (max-width: 450px) {
  html,
  body {
    font-size: 0.9rem !important;
  }
}